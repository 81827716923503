import React from "react";
import Card from "./Card";
import img1 from "../src/Pictures/img1.png";
import img2 from "../src/Pictures/img2.png";
import img3 from "../src/Pictures/img3.png";
import img4 from "../src/Pictures/img4.png";
import img5 from "../src/Pictures/img5.png";
import img6 from "../src/Pictures/img6.png";

function Service() {
  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Our Services</h1>
      </div>

      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              <Card
                style={{ width: "100%", height: "100%" }}
                title="Android Development"
                imgsrc={img1}
                text="Android operating system is the largest installed base among various mobile platforms across the
                 globe. Hundreds of millions of mobile devices are powered by Android in more than 190 countries of the 
                 world. It conquered around 71% of the global market share by the end of 2021, and this trend is growing
                  bigger every other day."
              />
              <Card
                style={{ width: "100%", height: "100%" }}
                title="Web Development"
                imgsrc={img2}
                text="Web development refers to the creating, building, and maintaining of websites.
               It includes aspects such as web design, web publishing,
               web programming, and database management. It is the creation of an application that works over the internet i.e. websites."
              />
              <Card
                style={{ width: "100%", height: "100%" }}
                title="Database Management"
                imgsrc={img3}
                text=" Database Management System (DBMS) is used to input, sort, organize and store data.

                     Database Management System (DBMS) is a Software for data storage & retrieval, for creating user interface (forms), reports, and for administering a DB system 
                      (security, access, etc.).  An implementation of a DBMS product to support 
                      various information needs in a company includes one or more databases, 
                      logic (business rules), backend code and user interface."
              />
              <Card
                style={{ width: "100%", height: "100%" }}
                title="Business Analytics"
                imgsrc={img4}
                text="Business analytics refers to the skills, technologies, and practices for continuous iterative
               exploration and investigation of past business performance to gain insight and drive business planning."
              />

              <Card
                style={{ width: "100%", height: "100%" }}
                title="Digital Marketing"
                imgsrc={img5}
                text="Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers,
               mobile phones and other digital media and platforms to promote products and services."
              />

              <Card
                style={{ width: "100%", height: "100%" }}
                title="Video Creation"
                imgsrc={img6}
                text="Video production is the process of producing video content for video. It is the equivalent of filmmaking, but with video recorded either as analog signals on videotape,
               digitally in video tape or as computer files stored on optical discs, hard drives, SSDs, magnetic tape or memory cards instead of film stock."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
