import React, { useState } from "react";
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    country: "91",
    phone: "",
  });

  const [flashMessage, setFlashMessage] = useState({
    message: "",
    type: "success",
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    axios
      .post("https://api.krmsolutions.online/Prod/records", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("API call successful", response.data);

        setFlashMessage({
          message: "Form submitted successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        console.error("API call failed", error);

        setFlashMessage({
          message: "An error occurred. Please try again later.",
          type: "error",
        });
      });
  }

  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Contact Us</h1>
      </div>

      <div className="container contact-div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Enter Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group pt-3">
                <label htmlFor="phone">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="+91-"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group pt-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group pt-3">
                <label htmlFor="message">Type your Message Here</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="col-12 pt-3">
                <button className="btn btn-outline-primary" type="submit">
                  Submit form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {flashMessage.message && (
        <div className={`alert alert-${flashMessage.type}`}>
          {flashMessage.message}
        </div>
      )}
    </>
  );
}

export default Contact;
// function onClickButton(event) {
//   event.preventDefault();
// }
// function Contact() {
//   return (
//     <>
//       <div className="my-5">
//         <h1 className="text-center">Contact Us</h1>
//       </div>

//       <div className="container contact-div">
//         <div className="row">
//           <div className="col-md-6 col-10 mx-auto">
//             <form>
//               <div className="form-group">
//                 <label for="exampleFormControlInput1">Enter Full Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="exampleFormControlInput1"
//                   placeholder="Enter Your Name"
//                 />
//               </div>
//               <div className="form-group pt-3">
//                 <label for="exampleFormControlInput1">Contact Number</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="exampleFormControlInput1"
//                   placeholder="+91-"
//                 />
//               </div>
//               <div className="form-group pt-3">
//                 <label for="exampleFormControlInput1">Email address</label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   id="exampleFormControlInput1"
//                   placeholder="name@example.com"
//                 />
//               </div>

//               <div className="form-group pt-3">
//                 <label for="exampleFormControlTextarea1">
//                   Type your Message Here
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="exampleFormControlTextarea1"
//                   rows="3"
//                   placeholder="Message"
//                 ></textarea>
//               </div>

//               <div className="col-12 pt-3">
//                 <button
//                   onClick={onClickButton}
//                   className="btn btn-outline-primary"
//                   type="submit"
//                 >
//                   Submit form
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Contact;
