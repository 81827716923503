import React from "react";
import { NavLink } from "react-router-dom";

function Card({ title, imgsrc, text, style }) {
  return (
    <>
      <div className="col-md-4 col-10 mx-auto">
        <div
          className="card  h-100 d-flex justify-content-center align-items-center"
          style={style}
        >
          <img
            src={imgsrc}
            className="card-img-top"
            alt="..."
            width="150px"
            height="250px"
          />
          <div className="card-body d-flex p-4  flex-column">
            <h5 className="card-title text-center">{title}</h5>
            <p className="card-text">{text}</p>

            <NavLink to="/contact" className="btn btn-info mt-auto">
              Get Quote
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
