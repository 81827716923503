import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Service from "./Service";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FloatingChatbot from './FloatingChatbot';

import ReactGA from "react-ga";
const TRACKING_ID = "UA-185978058-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  ReactGA.pageview(window.location.pathname);
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/Navbar" component={Navbar} />
        <Route exact path="/footer" component={Footer} />
        <Redirect to="/" />
      </Switch>

      <FloatingChatbot />
      <Footer />
    </>
  );
}

export default App;
