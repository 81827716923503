import React from "react";

function Cookie() {
document.addEventListener("DOMContentLoaded", function () {
    const cookieBanner = document.getElementById("cookie-banner");
    const acceptButton = document.getElementById("cookie-accept");
    const declineButton = document.getElementById("cookie-decline");

    if (localStorage.getItem("cookiesAccepted")) {
        cookieBanner.style.display = "none"; 
    }

    
    acceptButton.addEventListener("click", function () {
      
        localStorage.setItem("cookiesAccepted", true);
        cookieBanner.style.display = "none"; 
    });

  
    declineButton.addEventListener("click", function () {
        
        cookieBanner.style.display = "none"; 
    });
});

return(
    <>
     <div id="cookie-banner">
    <p>This website uses cookies to ensure you get the best experience on our website.</p>
    <button id="cookie-accept">Accept</button>
    <button id="cookie-decline">Decline</button>
</div>
    </>
)
}

export default Cookie;