import React from "react";
import Common from "./Common";
import web from "../src/Pictures/img2.png";
import Cookie from "./cookie";
function Home() {
  return (
    <>
      <Common
        name="Grow your business with"
        imgsrc={web}
        visit="/service"
        btname="Get started"
      />
      <Cookie />
    </>
  );
}

export default Home;