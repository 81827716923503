import React from "react";
import Common from "./Common";
import web from "../src/Pictures/img3.png";

function About() {
  return (
    <>
      <Common
        name="Welcome to the world of"
        imgsrc={web}
        visit="/contact"
        btname="Contact Us"
      />
      <section className="pt-5 bg-white mt-5">
        <div className="text-center pt-3">
          <h1>Our Company Values</h1>
        </div>
        <div className="container text-md-start mt-5">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-5 mx-auto  p-1">
              <h4 className="mb-3">Transperancy</h4>
              <p>
                Openness with our team & clients. We raise issues & provide
                solutions promptly.
              </p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-5 mx-auto  p-1">
              <h4 className="mb-3">Commitment</h4>
              <p>
                Our word and hardwork over everything else.Our clients and
                vision are our guiding beacons.
              </p>
            </div>
            <div className=" col-md-6 col-lg-4 col-xl-5 mx-auto  p-1">
              <h4 classname="mb-3">Self Improvement</h4>
              <p>
                Never stop learning, never stop evolving into who we want to be
                and where we want to be.
              </p>
            </div>
            <div className=" col-md-6 col-lg-4 col-xl-5 mx-auto  p-1 ">
              <h4 className="mb-3">Over Deliver</h4>
              <p>
                deliver more than value than our team & clients expect.Every
                day,over every delivery.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
