import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    // Function to define the googleTranslateElementInit function
    window.googleTranslateElementInit = function() {
      // eslint-disable-next-line no-new
      new window.google.translate.TranslateElement(
        {pageLanguage: 'en'}, 
        'google_translate_element'
      );
    };

    // Function to load the Google Translate script
    const loadGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.body.appendChild(script);
    };

    // Load the script after component mounts
    loadGoogleTranslateScript();
  }, []); // The empty array ensures this effect runs only once after initial render

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
