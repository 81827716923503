
import React from 'react';
import ChatBot from 'react-simple-chatbot';
import axios from 'axios';

const FloatingChatbot = () => {
  const steps = [
    {
        id: 'greet',
        message: 'Welcome to our App',
        trigger: '1',
      },

    {
      id: '1',
      message: 'Hello! What is your name?',
      trigger: 'name',
    },
    
    {
      id: 'name',
      user: true,
      validator: (value) => {
        if (!value.trim()) {
          return 'Please provide a valid name.';
        }
        return true;
      },
      trigger: '3',
    },
    {
      id: '3',
      message: 'Great! Can I also have your email address?',
      trigger: 'email',
    },
    {
      id: 'email',
      user: true,
      validator: (value) => {
        if (!/\S+@\S+\.\S+/.test(value)) {
          return 'Please enter a valid email.';
        }
        return true;
      },
      trigger: '5',
    },
    {
      id: '5',
      message: 'How can we assist you today?',
      trigger: 'userQuery',
    },
    {
      id: 'userQuery',
      user: true,
      trigger: '7',
    },
    {
      id: '7',
      message: 'Thank you for providing the information. Would you like to submit?',
      trigger: 'confirm',
    },
    {
      id: 'confirm',
      options: [
        { value: 'yes', label: 'Yes', trigger: 'submitInfo' },
        { value: 'no', label: 'No', trigger: 'end' },
      ],
    },
    {
      id: 'submitInfo',
      message: 'Submitting your information...',
      trigger: 'apiCall',
    },
    {
      id: 'apiCall',
      component: <SubmitForm />,
      asMessage: true,
      waitAction: true,
      trigger: 'end',
    },
    {
      id: 'end',
      message: 'Thank you! We will contact you soon.',
      end: true,
    },
  ];

  return <ChatBot steps={steps} floating={true} />;
};

const SubmitForm = ({ steps, triggerNextStep }) => {
    const name = steps.name.value;
    const email = steps.email.value;
    const message = steps.userQuery.value;
  
    React.useEffect(() => {
      // Destructured props are now directly used, no need to list 'props' in dependency array
      const formData = {
        name,
        email,
        message,
      };
  
      axios.post('https://api.krmsolutions.online/Prod/records', formData)
        .then(response => {
          console.log("API call successful", response.data);
          // Inform ChatBot conversation has completed or proceed with next step
          triggerNextStep({ trigger: 'end' }); // Use the destructured prop here
        })
        .catch(error => {
          console.error("API call failed", error);
          // Optionally, handle errors or trigger a different step
        });
  
      // Since the values used inside useEffect are defined outside of it,
      // we need to include them in the dependency array.
    }, [name, email, message, triggerNextStep]); // Include all dependencies here
  
    // Optionally, show a loading message or similar feedback
    return <div>Submitting your information...</div>;
  };
  

export default FloatingChatbot;
