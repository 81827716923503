import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted mt-5"
    >
      <section className="d-flex  justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className=" me-4 d-none d-sm-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="/#" className="me-5 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href="/#" className="me-5 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a
            href="https://www.google.com/search?q=KRMAKKALS+PRIVATE+LIMITED&rlz=1C1ONGR_enIN1008IN1008&oq=KRMAKKALS+PRIVATE+LIMITED&aqs=chrome.0.69i59j69i61j69i60l2.4712j0j1&sourceid=chrome&ie=UTF-8"
            target="_blank"
            rel="noopener noreferrer"
            className="me-5 text-reset"
          >
            <MDBIcon fab icon="google" />
          </a>
          <a href="/#" className="me-5 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
          <a
            href="https://www.linkedin.com/company/krmakkals-private-limited/about/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            className="me-5 text-reset"
          >
            <MDBIcon fab icon="linkedin" />
          </a>
          <a
            href="https://github.com/KRMAKKALS/krmsolutions.git"
            target="_blank"
            rel="noopener noreferrer"
            className="me-5 text-reset"
          >
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="gem" className="me-3" />
                KRMAKKALS Private Limited
              </h6>
              <p>
                We are a cutting-edge development team as it grows. We are the
                ideal team of divergent thinker who understands industry best
                practices and has experience with multiple coding languages.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Products</h6>
              <p>
                <a href="#!" className="text-reset">
                  ChatChey
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 text-center"
            >
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-3" />
                KASARAGOD, KL 671321, IN
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                admin@krmsolutions.online
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> + 91 9526320219
              </p>
              <p>
                <MDBIcon icon="print" className="me-3" /> + 91 9526167612
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2021 Copyright:
        <a
          className="text-reset fw-bold"
          href="https://www.krmsolutions.online"
        >
          KRMAKKALS PRIVATE LIMITED
        </a>
      </div>
    </MDBFooter>
  );
}
